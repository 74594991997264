.game-teaser {
  position: relative;
  width: 100vw;
  left: -2rem;

  >* {
    padding: 0 2rem;
  }

  .emoji {
    @include emojis;
    margin-bottom:3rem;
  }

  p {
    text-align: center;
  }

  strong {
    text-align: center;
  }

  h3 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 3rem;

    >strong,
    >span {
      font-size: 1.8rem;

    }
  }

  .colored {
    color: $suite-red;
  }

  .horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &.two-images {
      li {
        width: calc(50% - 2rem);
        margin: 0 1rem;

        img {
          width: 100%;
        }
      }
    }
  }

  .see-you-next-week {
    margin-top: 4rem;
  }

  a {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    width: max-content;
    margin: 2rem auto;

    svg {
      margin-right: 8px;
      font-size: 1.2rem;
      height: 1.2rem;
      width: auto;
      position: relative;
      top: -0.1rem;
    }
  }

  article {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 50vh;

    img:not(.curtain) {
      &.width-25 {
        width: 25%;
      }

      &.width-50 {
        width: 50%;
      }

      &.width-75 {
        width: 75%;
      }

      &.width-100 {
        width: 100%;
      }
    }

    .curtain {
      height: 100%;
      width: 60%;
      position: absolute;
      left: 0;
      top: 0;
      transition: left 2s;
      z-index:2;

      &:nth-child(2) {
        right: 0%;
        left: auto;
        transform: scaleX(-1);
        transition: right 2s;

      }
    }

    >div{
      opacity:.1;
      transition:opacity 1s;
      position:relative;
      z-index:1;
    }

    &.state-1 {
      >div{
        opacity:1;
      }
      .curtain {
        left: -60%;
        right: auto;



        &:nth-child(2) {
          right: -60%;
          left: auto;

        }
      }
    }

    &.state-2 {
      height: auto;

      >div{
        opacity:1;
      }

      .curtain {
        left: -60%;
        right: auto;



        &:nth-child(2) {
          right: -60%;
          left: auto;

        }
      }
    }
  }
}