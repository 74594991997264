.game-lesson {
  .emoji {
    @include emojis;
  }

  p {
    text-align: center;
  }

  h2 {
    font-size: 2.2rem;
    text-align: center;
    color: $suite-red;
  }

  .vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 1rem;
    }
  }

  .lesson {
    padding: 2rem;
    border-radius: .5rem;
    border: dashed 1px #ccc;
    margin-top: 4rem;
    @include lessons;
    width: calc(100% + 2rem);
    position: relative;
    left: -1rem;
    background: rgba(255, 255, 255, .6)
  }

  button {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    margin: 2rem auto 0;
  }
}