footer {
  height: 8vh;
  width: 100vw;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $black;
  border-top:solid 0.1rem $very-dark-grey;

  &.invisible {
    display: none;
  }

  nav {
    width: 100%;
    height: 100%;

    ul {
      @include flex-wrap;
      height: 100%;
      justify-content: space-around;
      align-items: center;

      li {
        width: max-content;
        height: 80%;
        width:calc(8vh * 0.8);

        a {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          border: 0;
          outline: 0;
          @include flex-column-wrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: .5rem;
          
          img {
            height:60%;
          }

          &.active {
            background: rgba(218, 51, 62, .2);
            border:solid .1rem #DA333E;
            img {
              height:calc(60% + 0.1rem);
            }
          }

          
        }
        &:nth-child(1){
          a{
            &.active {
              background: rgba(66, 71, 191, .2);
              border:solid .1rem #4247BF;
            }
          }
        }
        &:nth-child(2){
          img{
            height:70%;
          }
          a{
            &.active {
              background: rgba(255, 255, 255, .2);
              border:solid .1rem #fff;
              img {
                height:calc(70% + 0.1rem);
              }
            }
          }
        }
      }
    }
  }
}