.login-component {
  form {
    height: 100%;
    @include flex-column-wrap;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;

    input[type=text] {
      @include large-input;
      caret-color: transparent;
      color: white;
    }

    button {
      @include round-button;
      margin-top: 4rem;
    }
  }
}