.heart {
  position: absolute;
  width: 70vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
  opacity: 1;
  top: 0;

  svg {
    fill: $suite-red;
    transition: fill .2s;
  }
}