.lesson-button {
  width: 100%;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 90vw;
    min-height: 8rem;
    border-radius: 99999rem;
    text-decoration: none;
    color: white;
    background-color: rgba(25,25,25,.5);
    border:solid 1px $dark-grey;
    padding: 1rem 2rem;
    transition: background-color .2s, border-color .2s;
    margin: 1rem 0;

    &:hover {
      background-color: $very-dark-grey;
      border-color: $very-dark-grey;
    }
  }
}