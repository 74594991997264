.lessons-component {
  justify-content: flex-start;

  h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.6rem;
  }

  ul {
    margin-top: 2rem;
    @include flex-column-wrap;
    width: 100%;
  }
}