@import "./others/alerts.css";

/* Fonts */

/* Paragraphes */
@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Regular.ttf);
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-SemiBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Bold.ttf);
  font-weight: 800;
}

/* Subtitles */
@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Regular.otf);
}

@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Italic.otf);
  font-style: italic;
}

/* Titles */
@font-face {
  font-family: Petita;
  src: url(../assets/fonts/PetitaBold.ttf);
}

/* Colors */

$red: #e21617;
$suite-red: #B52032;
$black: #191919;
$white: #f6f6f6;
$very-dark-grey: #4F4E4E;
$dark-grey: #777;
$light-grey: #ccc;
$very-light-grey: #eee;
$light-green: #A5DC86;
$light-red: #F27474;
$green: #019640;

:root {
  --main-color: #ffffff;
}

/* Mixins */

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-column-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

@mixin large-input {
  width: 12ch;
  font-size: 4rem;
  text-align: center;
  outline: 0;
  border: 0;
  border-bottom: solid 2px $light-grey;
  background: transparent;
  font-family: Hind;
  transition: border-color .2s;

  &:focus {
    border-color: var(--main-color);
  }
}

@mixin button($bg, $text, $border, $stroke, $bg-hover, $text-hover, $border-hover, $stroke-hover) {
  padding: 10px 20px;
  border: solid 2px $border;
  color: $text;
  background-color: $bg;
  font-size: inherit;
  border-radius: 9999px;
  transition: background .2s, color .2s, border-color .2s, color .2s;
  text-decoration: none;
  cursor: pointer;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    stroke: $stroke;
    fill: $stroke;
    transition: stroke .2s;
  }

  &:hover {
    color: $text-hover;
    background-color: $bg-hover;
    border-color: $border-hover;

    path {
      stroke: $stroke-hover;
    }
  }
}

@mixin round-button {
  padding: 0 .2rem;
  height: calc(30vw + .2rem);
  width: 30vw;
  border: solid 2px;
  outline: 0;
  color: white;
  border-radius: 9999px;
  transition: background-color .2s, color .2s, border-color .2s;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: Hind;
  @include flex-column-wrap;
  justify-content: center;
  align-items: center;
  max-width: 15rem;
  max-height: 15rem;
  background-color: var(--main-color);
  border-color: var(--main-color);

  svg {
    height: 1.6rem;
    margin-top: 0.2rem;

    path {
      transition: stroke .2s;
    }
  }

  &:hover {
    background-color: $white !important;
    color: var(--main-color);

    svg {
      path {
        stroke: var(--main-color);
      }

    }
  }
}

@mixin answers {
  margin-top: 3rem;

  li {
    width: max-content;

    button {
      cursor: pointer;
      width: max-content;
      padding: 3rem 2rem;
      background: rgba(255, 255, 255, .1);
      border: 0;
      border-radius: 2rem;
      width: 80vw;

      img {
        width: 6rem;
        margin: 0 1rem;
      }
    }

    &.answer-image {
      button {
        background: transparent;
        outline: 0;
        border: 0;
      }
    }
  }
}

@mixin metal-bg {
  background-image: url(../../assets/images/metal-pattern.png);
  background-size: 100%;
  padding: 2rem;
}

@mixin popup {
  @include flex-wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 0;
  z-index: 4;
  transition: opacity .3s;
  opacity: 0;

  @media screen and (orientation: landscape) and (max-width: 680px) {
    align-items: flex-start;
    margin-top: 1rem !important;
  }

  > :nth-child(1) {
    background-color: $black;
    max-width: 800px;
    width: 90%;
    @include flex-column-nowrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    top: 100px;
    transition: top .3s;
    border-radius: 5px;
    max-height: 100vh;

    @media screen and (orientation: landscape) and (max-width: 680px) {
      max-height: 75vh;
    }

    >form {
      overflow-y: auto !important;
    }

    @media (orientation: portrait) {
      width: 95% !important;
      padding: 30px 10px !important;
    }
  }

  &.visible {
    opacity: 1;

    > :nth-child(1) {
      top: 0;
    }
  }
}

@mixin lessons {
  text-align: center;

  h3 {
    img {
      display: inline-block;
      height: 4rem;
      vertical-align: middle;
      margin: 0 .5rem;
    }
  }

  .align-top {
    //align-items: flex-start;
  }

  .line-break {
    margin-bottom: 1.5rem;
  }

  >div:not(.slide-lesson) {
    &.vertical {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.horizontal {
      display: flex;
      flex-direction: row;

      &.columns-2 {
        >* {
          width: calc(50% - 1rem);
          margin: 0 .5rem;
        }

        >div {
          margin: 1rem 0;

          p {
            margin: .5rem 0;
          }
        }
        &.cours-3-2-columns{
          width:100%;
          margin:0;
          margin-top:2rem;
          .line-break{
            margin-bottom:.5rem;
          }
          >div{
            margin:0 0 0 2rem;
            >img{
              max-height:7rem;
              width:auto;
            }
          }
        }
      }

      &.columns-3 {
        >* {
          width: calc(33% - 1rem);
          margin: 0 .5rem;
        }
      }

      &.columns-4 {
        >* {
          width: calc(25% - 1rem);
          margin: 0 .5rem;
        }

        img {
          width: 100%;

          &.color-point {
            width: 4rem;
          }
        }
      }
    }

    margin: 4rem 0 2rem;

    &.no-margin-top {
      margin-top: 0;
    }

    >h3 {
      text-transform: uppercase;
      text-align: center;
    }

    >strong {
      text-align: center;
      margin: .5rem 0;
    }

    .line-break {
      margin-bottom: 1.5rem;
    }

    &.s1e2-tplt1 {
      display: flex;
      margin: 1rem 0;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }

      div {
        width: 50%;
        margin: 0 1rem;
      }

      .profile-left {
        text-align: center;
      }

      .cards-right div {
        border: solid 1px white;
        border-radius: .5rem;
        padding: 1rem;
        text-align: center;
        margin: .5rem 0;
        width: 100%;
      }
    }
  }

    img {
      &.width-25 {
        width: 25%;
      }

      &.width-50 {
        width: 50%;
      }

      &.width-75 {
        width: 75%;
      }

      &.width-100 {
        width: 100%;
      }

      &.line-break {
        margin-bottom: 1.5rem;
      }
    }

    .answers {
      @include answers;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      li {
        button {
          border: solid 2px $suite-red;
          pointer-events: none;
          cursor: default;
        }

        &.correct {
          button {
            border-color: $green;
          }

        }
      }
    }

    ul {
      &.list-style-none {
        list-style: none;
        padding-left: 0;
      }

      &.list-style {
        padding-left: .5rem;

        &.discs {
          list-style: disc;
        }
      }
    }

    .list-left{
      text-align: left;
      display:flex;
      width:100%;
      justify-content: center;
      ul{
        width:80%;
        text-align: left;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: disc;
      }
    }

    .black-card{
      background-color:#000;
      color:white;
      text-align: center;
      img{
        width:100%;
      }
      p{
        width:100%;
        text-align: center;
        padding:.5rem;
      }
    }

  


}

@mixin slides-numbers-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  label {
    font-size: 1.4rem;
  }

  input {
    width: 4ch;
    font-family: Hind;
    font-size: 1.6rem;
  }
}

@mixin emojis {
  height: 8.5rem;
  margin: 1.5rem auto 1.5rem;
  display: block;

  &.animate {
    transition: transform .2s;
  }

  transform: scale(.4);

  &.fat {
    transform: scale(1);
  }
}

@mixin popup-form {
  @include flex-column-wrap;
  margin-top: 10px;
  width: 500px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (orientation: portrait) {
    width: 100%;
  }

  .form-element {
    margin: 8px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include flex-column-wrap();

    input[type=text],
    input[type=time],
    input[type=date],
    input[type=email],
    select {
      padding: 4px 5px;
      text-align: center;
      font-family: Hind;
      font-size: 1.6rem;
      width: 90%;
      border-radius: 5px;
      border: solid 1px $dark-grey;
    }

    input[type=checkbox] {
      height: 1.6rem;
      width: 1.6rem;
    }

    label {
      margin-bottom: 5px;
      cursor: pointer;
    }

    .form-inputs {
      @include flex-wrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      input {
        width: max-content;
        margin: 0 10px;
      }
    }
  }

  .form-actions {
    @include flex-wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    button {
      @include flex-wrap;
      @include button;
      justify-content: center;
      align-items: center;
      border: solid 1px;
      border-radius: 9999px;
      background: transparent;
      margin: 0 5px;
      transition: color 0s, border-color 0s;

      &.next-button,
      &.create-button,
      &.add-button {
        font-weight: bold;
        color: $cta-green;

        &:focus {
          background-color: $cta-green;
          color: $white;
        }
      }

      &.edit-appoint {
        font-weight: bold;
        color: $cta-orange;

        &:focus {
          background-color: $cta-orange;
          color: $white;
        }
      }

      &.back-button {
        color: $black;
        opacity: .5;

        &:focus {
          background-color: rgba(0, 0, 0, .5);
          color: $white;
        }
      }

      &.close-button,
      &.remove-appoint {
        font-weight: bold;
        color: $cta-red;

        &:focus {
          background-color: $cta-red;
          color: $white;
        }
      }

      span {
        display: inline-block;
        width: 0;
        transition: width .2s, margin .2s;
        overflow: hidden;
        margin: 0;

        svg {
          width: 1.4rem;
          @include flex-wrap;
          justify-content: center;
          align-items: center;
        }
      }

      &:hover {
        span {
          width: 1.6rem;
        }

        &.next-button,
        &.create-button,
        &.remove-appoint,
        &.edit-appoint {
          span {
            margin: 0 0 0 7px;
          }
        }

        &.back-button,
        &.close-button,
        &.add-button {
          span {
            margin: 0 7px 0 0;
          }
        }
      }
    }
  }



}

/*@mixin for-large-screen {
  @media (min-width: 1001px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 550px) {
    @content;
  }
}*/

/* General */
#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#application {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 4rem - 10vh);
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  width: 100%;
  overflow-x: hidden;

  body {
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    align-items: center;
    color: $white;
    background-color: $black;
    font-family: Hind;
    font-size: 1.6rem;
    //background-color: #888;
    //background-color:#650b10;
    /*background-image: url(../assets/images/bglasuite.png);*/
    /*background-size: cover;
    background-position: cover;*/
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow-x: hidden;

    &.lessons-bg{
      .slides-header{
        margin-bottom:0;
      }
      .emoji{
        margin-top: 0;
      }
    }

    .fake-bg {
      display: none;
    }

    &.fingers {
      .fake-bg {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $black;
        z-index: 1;
        background-image: url(../assets/images/bg-fingers.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &.lessons-bg {
      .fake-bg {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $black;
        z-index: 1;
        background-image: url(../assets/images/bg-koi.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(180deg);
      }
    }

    &.files-bg {
      .fake-bg {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $black;
        z-index: 1;
        background-image: url(../assets/images/bg-white-fingers.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &.recap-bg {
      .fake-bg {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: $black;
        z-index: 1;
        background-image: url(../assets/images/bg-fingers.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .lesson-header {
      display: none;
    }

    &.day-mode {
      background-color: white;
      color: $black;

      .fake-bg {
        background-color: white;
        background-image: url(../assets/images/bg-white-fingers.png);
      }

      h1 {
        color: $black !important;
        font-weight: bold;
      }

      .lesson-header {
        display: block;
      }
    }

    #root {
      height: 100%;
      width: 100%;
      min-height: calc(100vh - 4rem - 10vh);
    }

    * {
      box-sizing: border-box;
      font-size: 1.6rem;
    }

    button {
      font-family: Hind;
    }

    .only-for-mobile {
      background-color: $black;
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 9999;
      padding: 4rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      display: flex;
      flex-direction: column;

      @media (orientation: portrait) {
        display: none;
      }

      p {
        display: block;
        font-size: 1.8rem;
      }

      .emoji {
        width: 10rem !important;
        margin-bottom: 4rem !important;
      }
    }

    header,
    main,
    footer {
      z-index: 2;
    }

    main {
      @include flex-column-wrap;
      justify-content: center;
      padding: 0;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      font-family: Petita;
      font-size: 1.8rem;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      margin: 1rem 0 2rem;
    }

    svg {
      pointer-events: none;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    textarea {
      resize: none;
      font-family: Hind;
      font-size: 1.6rem;
    }

    input {
      font-family: Hind;
    }

    .discreet-text {
      font-size: 1.5rem;
      color: $dark-grey;
    }
  }
}