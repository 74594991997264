.download-component{
  justify-content: center;
  align-items: center;
  div{
    background-color: rgba(0,0,0,.4);
    border:solid $very-dark-grey .1rem;
    border-radius: .5rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:2rem;
    height:max-content;
    min-height:auto;
    text-align: center;
    transform: scale(0.7);
    transition: transform .5s, opacity .5s;
    opacity:0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
    img{
      width:90%;
    }
    button{
      margin-top:2rem;
      @include button($black, $suite-red, $suite-red, $suite-red, $suite-red, white, $suite-red, white);
    }
    &.loaded{
      transform: scale(1);
      opacity:1;
    }
  }
}