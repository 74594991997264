.halo-container{
  overflow:hidden;
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0;
  display:flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .halo{
    &.go{
      width: 22vh;
      height: 22vh;
      border-radius: 50%;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 80%);
      animation: haloEffect 0.8s ease-out forwards;
    }
    &.invisible{
      display:none;
    }
    @keyframes haloEffect {
      0% {
          transform: scale(0);
          opacity: 0.8;
      }
      50% {
          opacity: 1;
      }
      100% {
          transform: scale(10);
          opacity: 0;
      }
    }
  }
}