.welcome-component {
  justify-content: center;

  h1 {
    font-family: Hind;
    font-size: 4.5rem;
    text-transform: none;
    color: $white;
    font-weight: 100;
    text-align: right;
    line-height: 5.2rem;

    strong {
      font-size: 4.5rem;
      color: $white;
    }
  }

  p {
    text-align: center;
    margin-top: 1rem;
    width: 95%;
  }

  .round-btn {
    @include round-button;
    margin-top: 4rem;
    text-decoration: none;
  }

  *:not(.heart) {
    position: relative;
    z-index: 2;
  }
}