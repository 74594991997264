.pot-component {
  justify-content: flex-start;

  .pot-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, .3);
    border-radius: .5rem;
    padding: 1rem 3rem;
    margin-top: 2rem;
    position: relative;

    .dimmer {
      background: transparent;
      -webkit-text-size-adjust: 100%;
      color: #f6f6f6;
      font-family: Hind;
      --main-color: #e21617;
      margin: 0;
      box-sizing: border-box;
      font-size: 1.6rem;
      animation-iteration-count: 1;
      animation-timing-function: ease;
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      padding: 1em;
      line-height: 1;
      animation-fill-mode: both;
      animation-duration: .5s;
      transition: background-color .5s linear;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      user-select: none;
      will-change: opacity;
      z-index: 1000;
      visibility: visible !important;
      opacity: 1;
      background: transparent;
      display: flex !important;

      .content {
        -webkit-text-size-adjust: 100%;
        font-family: Hind;
        --main-color: #e21617;
        text-align: center;
        line-height: 1;
        visibility: visible !important;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 1.6rem;
        user-select: text;
        color: #fff;

        .loader {
          -webkit-text-size-adjust: 100%;
          font-family: Hind;
          --main-color: #e21617;
          line-height: 1;
          visibility: visible !important;
          user-select: text;
          padding: 0;
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: 0;
          text-align: center;
          z-index: 1000;
          transform: translateX(-50%) translateY(-50%);
          width: 2.28571429rem;
          height: 2.28571429rem;
          font-size: 1em;
          display: block;
          color: rgba(255, 255, 255, .9);
        }
      }
    }

    h2 {
      font-size: 4rem;
    }

    p {
      color: $light-grey;
      position: relative;
      top: -1rem;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    button {
      @include button(transparent, white, $dark-grey, white, $dark-grey, white, $dark-grey, white);

      &:nth-child(1) {
        margin-top: 1.25rem;
      }

      &:nth-child(2) {
        @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
      }

      width: max-content;
      max-width: 100%;
      margin: .5rem 0;
    }
  }

  h3 {
    margin-top: 4rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 4rem;

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .payout-popup {
    @include popup;

    >div {
      >div {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        h3 {
          margin-top: 0;
          width: 75%;
          margin-bottom: 2rem;
        }

        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 75%;

          input {
            text-align: center;
            width: 100%;
            display: block;
            border: 0;
            border-radius: .3rem;
            outline: 0;
            padding: .5rem;
            margin-bottom: 2rem;
          }

          button {
            @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
          }
        }
      }

    }
  }

  .button-like {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    position: absolute;
    bottom: 0;

    svg {
      margin-right: 8px;
      font-size: 1.2rem;
      height: 1.2rem;
      width: auto;
      position: relative;
      top: -0.1rem;
    }
  }

  .history {
    background-color: rgba(0, 0, 0, .2);
    border-radius: .5rem;
    margin-bottom: 8rem;
    margin-top: 3rem;

    li {
      width: 100%;
      padding: 1rem 2rem;

      &:nth-child(even) {
        background: rgba(0, 0, 0, .4);
      }

      p {
        &:nth-child(2) {
          color: $light-red;
        }

        &.sponsor-plus {

          &:nth-child(2) {
            color: $light-green;
          }

        }
      }
    }
  }
}