.ranking-component {
  justify-content: flex-start;

  >ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      display: flex;
      width: 100%;
      height: max-content;
      justify-content: space-between;
      border-radius: 9999px;
      border: 1px solid $dark-grey;
      align-items: center;
      padding: 1rem;
      margin: .5rem 0;

      &.me {
        background: rgba(226, 22, 23, .4);
        margin-bottom: 1.5rem;
      }

      h3 {
        height: 90%;
        padding: .5rem;
        width: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid white;
        font-size: 2.2rem;
      }

      >article {
        display: flex;
        width: calc(100% - 7rem);

        .profile-picture {
          width: 5rem;
          height: 5rem;
          border-radius: 9999px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: bold;
          font-family: Arial;
          margin: 0 1rem;

          p {
            font-size: 2.2rem;
            color: $black;
          }
        }
      }
    }
  }
}