.red-screen{
  background-color: $red;
    position: fixed;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity 0.1s;
    left: 0;
    top: 0;
    z-index: 9999;
}