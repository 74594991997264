.pin-component {
  form {
    height: 100%;
    @include flex-column-wrap;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .code-pin {
    input {
      font-size: 6rem;
      width: 2ch;
      text-align: center;
      margin: 1vw;
      caret-color: transparent;
      border-radius: 5px;
      border: solid 2px $light-grey;
      outline: 0;

      &:focus {
        border-color: $suite-red;
      }
    }
  }

  button {
    @include round-button;
    margin-top: 4rem;
  }
}