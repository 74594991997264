@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-15px);}
	60% {transform: translateY(-7.5px);}
}

.game-slides {
  @include flex-column-wrap;
  align-items: center;
  min-height: 100%;
  width: 100%;
  @include metal-bg;
  position: relative;

  .bg-koi {
    display: none;
  }

  &.lesson-slide {
    padding: 0;
    background: transparent;

    .bg-koi {
      display: block;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      background-color: $black;
      background-image: url(../../assets/images/bg-koi.png);
      background-size: cover;
      background-position: center;
      transform: rotate(180deg);
    }
  }

  &.color-white {
    >article {
      background-color: white;
      color: $black;
      padding: 2rem 0;

      .answers li button {
        background: rgba(0, 0, 0, 0.2) !important;
        color: $black;
      }
    }
  }

  .emoji {
    @include emojis;
  }


  .next-slide {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    width: max-content;
  }

  article {
    text-align: center;

    h3 {
      font-size: 1.8rem;
      width: 100%;
    }

    .vertical {
      @include flex-column-wrap;
      justify-content: center;
      align-items: center;
    }

    .horizontal {
      @include flex-wrap;
      justify-content: center;
      align-items: center;
      &.columns-2 {
        >* {
          width: calc(50% - 2rem);
          margin: 0 .5rem;
          img{
            width:100%;
          }
        }

        >div {
          margin: 1rem 1rem;

          p {
            margin: .5rem 0;
          }
        }
      }

      &.columns-3 {
        >* {
          width: calc(33% - 1rem);
          margin: 0 .5rem;
        }
      }

      &.columns-4 {
        >* {
          width: calc(25% - 1rem);
          margin: 0 .5rem;
        }

        img {
          width: 100%;

          &.color-point {
            width: 4rem;
          }
        }
      }
    }

    .opacity-25 {
      opacity: .25;
    }

    article {
      min-height: 100%;
    }

    &.tplt-inline-text {
      @include lessons;

      margin-bottom: 2rem;

      &.lesson {
        position: relative;
        width: 100%;

        .slide-lesson {
          @include lessons;
          position: absolute;
          top: 0;
          display: none;
          width: calc(100vw - 4rem);
          opacity: 0;
          //background-color: $black;
          pointer-events: none;

          .paragraph-title {
            background: linear-gradient(to right, #810D1C, #3E0407);
            margin-bottom: 0 !important;
            padding: 2rem 1rem 2rem;
          }

          .paragraph-content {
            margin-top: 0 !important;
            @include lessons;
            background: linear-gradient(to right, #810D1C, #3E0407);
            padding: 0 1rem 2rem;
          }

          &.visible {
            opacity: 1;
            pointer-events: auto;
          }

          .discreet {
            opacity: .5;
            font-size: 1.4rem;
          }

          .jauge-container {
            width: 60vw;
            background: rgba(0, 0, 0, .4);
            display: block;
            border-radius: .5rem;
            margin: 1rem auto 0;
            overflow: hidden;
            height: 2.5rem;
            z-index: 1;
            position: relative;

            .jauge-content {
              background-color: $suite-red;
              ;
              width: 0;
              height: 100%;
              display: block;
              padding-top: .1rem;
            }

            p {
              z-index: 2;
              position: absolute;
              top: 0;
              width: 60vw;
              text-align: center;
            }
          }

          .next-slide {
            margin-top: 5rem;
            opacity: 0;
            transform: scale(.5);
            transition: transform .3s, opacity .3s;
            pointer-events: none;
            margin-bottom: 2rem;

            &.visible {
              transform: scale(1);
              opacity: 1;
              pointer-events: auto;
            }
          }

          .mask {
            display: block;
            position: absolute;
            background-color: $black;
            width: 100%;
            bottom: 11.9rem;
            margin: 0 !important;
            z-index: 3;
          }
        }
      }

      /*.images-container {
        width: 100%;
        margin: 2rem 0 3rem;

        img {
          width: 80%;
          margin: 1rem 0;
        }
      }

      p {
        margin: .5rem 0;
      }*/
    }

    &.tplt-classic-question {

      justify-content: center;

      h3 {
        margin-bottom: 1.5rem;

        img {
          display: inline-block;
          height: 4rem;
          vertical-align: middle;
          margin: 0 .5rem;
        }
      }

      .question {
        img {
          &.width-25 {
            width: 25%;
          }

          &.width-50 {
            width: 50%;
          }

          &.width-75 {
            width: 75%;
          }

          &.width-100 {
            width: 100%;
          }
        }
      }

      .color {
        width: 6rem;
        margin: 0 .5rem;
      }

      .three-pictures {
        width: 100%;

        li {
          width: calc((100vw - 4rem - 3rem) / 3);
          height: calc((100vw - 4rem - 3rem) / 3);
          margin: .5rem;
          overflow: hidden;
          display: block;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .answers {
        @include answers;
      }
    }

    &.tplt-round-question {
      .round-four {
        width: 100%;

        .round-input {
          background: transparent;
          border: solid 1px $light-grey;
          width: 5rem;
          height: 5rem;
          margin: 2rem;

          img {
            width: 5rem;
            height: 5rem;
            object-fit: cover;
            object-position: center;
            pointer-events: none;
          }

          &:hover,
          &:focus {
            border-color: $dark-grey;
          }
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: calc(100% - 18rem);
          }
        }
      }

      .round-pictures {
        @include popup;

        h3 {
          margin-bottom: 2rem;
        }

        button {
          padding: 0;
          margin: 1rem;
          width: max-content;
          height: max-content;
          display: inline-block;
          background-color: transparent;
          border: solid 2px $white;
          outline: none;
          width: 12rem;
          height: 12rem;
          cursor: pointer;
          border-radius: .5rem;
          overflow: hidden;


          &:hover,
          &:focus {
            border-color: $suite-red
          }

          img {
            width: 12rem;
            height: 12rem;
            object-fit: cover;
            object-position: center;
            pointer-events: none;
          }
        }
      }
    }

    &.tplt-color-wheel {
      > svg{
        margin:4rem 0;
        pointer-events: all;
        overflow: visible;
        path{
          pointer-events: all;
          display:block;
          stroke-width: .2rem;
          cursor: pointer;
          &.selected{
            stroke:white;
            stroke-width: .2rem;
          }
        }
      }
    }

    &.tplt-round-numbers {
      img {
        width: 50vw;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        margin: 2rem 0;
      }

      .instructions {
        margin-bottom: 2rem;
      }

      .number-inputs {
        display: flex;
        justify-content: space-around;
        width: 50vw;
        align-items: center;
        margin: 0 auto;

        input {
          width: 25%;
          padding: .1rem;
          text-align: center;
          font-size: 1.6rem;
          font-family: Hind;
          margin-bottom: 2rem;
        }
      }

      .input-container {
        @include slides-numbers-inputs;
      }
    }

    &.tplt-multi-selects {
      h3 {
        margin-bottom: 2rem;
      }

      .all-answers {
        margin-bottom: 3rem;
      }

      .selects-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          width: calc(50% - 1rem);
          margin: 1rem .5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .fake-label {
            width: 100%;

            img {
              width: 75%;
            }
          }

          select {
            width: 75%;
            text-align: center;
            margin: 0 auto;
            //margin-top: 5px;
          }
        }

        &.horizontal-display{
          flex-direction: column;
          li{
            width:100%;
            margin: 2rem 0;
          }
        }
      }
    }

    &.tplt-colors-chips{
      .question{
        margin-bottom:3.5rem;
      }
      .answer-container{
        margin-bottom:3.5rem;
        img{
          width:80%;
        }
        .answer-chips{
          display:flex;
          justify-content: center;
          li{
            margin:1.5rem 1rem;
            label{
              width:5rem;
              height:5rem;
              border-radius:9999rem;
              display:flex;
              cursor:pointer;
            }
            input{
              display:none;

              &:checked + label{
                border:solid .2rem $white;
              }
            }
          }
        }
      }
    }

    &.tplt-pictures-chips{
      .question{
        margin-bottom:3.5rem;
      }
      .answer-container{
        margin-bottom:3.5rem;
        img{
          width:80%;
        }
        .answer-chips{
          display:flex;
          justify-content: center;
          flex-wrap: wrap;
          li{
            margin:1.5rem 1rem;
            width:calc(50% - 2rem);
            label{
              width:100%;
              display:flex;
              cursor:pointer;
              background: rgba(255, 255, 255, .1);
              border: 0;
              border-radius: 2rem;
              padding:1rem;
              img{
                width:100%;
                pointer-events: none;
              }
            }
            input{
              display:none;

              &:checked + label{
                border: solid .2rem $white;
              }
            }
          }
        }
      }
    }

    button:not(.round-input):not(.round-pictures-button) {
      @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
      margin: 2rem auto 0;
    }

  }

  button:not(.round-input):not(.round-pictures-button) {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    margin: 2rem auto 0;
  }
}

// SURCOUCHE SLIDE PAR SLIDE

body{
  &.slide-4-9{
    .game-slides{
      article.tplt-pictures-chips .answer-container .answer-chips{
      flex-direction: column;
      justify-content: center;
      align-items: center;
        li{
          width:80%;
          margin:1rem 0;
        }
      }
    }
  }
  &.slide-4-10{
    .game-slides{
      .tplt-inline-text{
        img{
          &:nth-of-type(1){
            margin-top:3.5rem;
          }
        }
      }
    }
  }
  &.slide-4-11{
    div{
      >.width-50{
        margin-bottom:3.5rem !important;
        height:10rem;
        width:auto !important;
        display:block;
        margin-left:auto;
        margin-right:auto;
      }
    }
  }
  &.slide-4-13, &.slide-4-15, &.slide-4-18, &.slide-4-21, &.slide-4-24, &.slide-4-27, &.slide-4-30{
    .columns-2{
      div{
        margin-left:.5rem !important;
        margin-right:.5rem !important;
        .line-break{
          margin-bottom:0.5rem !important;
        }
      }
    }
  }

  &.slide-4-31{
    .paragraph-title{
      img{
        width:75% !important;
      }
    }
  }

  &.slide-4-32{
    .answer-picture{
      img{
        width:100% !important;
        margin-top:1.5rem;
      }
    }
  }

  &.slide-4-33{
    .tplt-inline-text{
      p{
        margin-top:4rem;
      }
      h3:not(:nth-of-type(1)){
        font-size:5rem;
      }
    }
  }
  &.slide-4-34{
    img.line-break{
      margin-bottom:0 !important;
    }
  }
  &.slide-4-35, &.slide-4-36{
    .answers{
      strong{
        font-size:2.5rem;
        display:block;
      }
    }
  }

  &.slide-4-37{
    .arrow{
      margin-top:1.5rem !important;
      width:auto !important;
      height:5rem !important;
      animation: bounce2 2s ease infinite;
      position:relative;
      top:7px !important;
    }
  }
  &.slide-4-38{
    .paragraph-title{
      .columns-2{
        display:flex;
        margin-top:1.5rem;
        img{
          width:calc(50% - 2rem) !important;
        }
      }
    }
    .paragraph-content{
      ul{
        text-align:left;
        padding-left:20px;
        list-style: disc;
        margin:2rem 0  3.5rem !important;
      }
    }
  }
}