.account-component {
  justify-content: flex-start;
  position: relative;

  .warning {
    text-align: center;
    color: $light-grey;
    margin-bottom: 2rem;
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-family: Hind;
  }

  .infos-settings{
    li{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom:1rem;
      a{
        color:white;
        text-decoration: none;
        border-bottom: .1rem dashed;
        display:flex;
        justify-content: center;
        align-items: center;
        width:max-content;
        text-align: center;
        opacity:.8;
        transition:opacity .2s;
        img{
          height:1.6rem;
          margin-left:1rem;
        }
        &:hover{
          opacity:1;
        }
      }
    }
  }

  article{
    margin: 2rem 0;
  }

  .langs-article {
    ul {
      display: flex;
      justify-content: center;

      li {
        margin: .5rem;

        label {
          height: 4rem;
          width: 5.5rem;
          position: relative;
          cursor: pointer;
          display: block;

          input {
            display: none;
          }

          .flag {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            border-radius: .5rem;
          }

          >input:checked+.flag {
            border: solid 1px white;
          }
        }
      }
    }
  }

  .text-speed{
    ul{
      display:flex;
      justify-content: center;
      li{
        margin:0 1.5rem;
        label{
          height:8rem;
          div{
            width:6rem;
            display:flex;
            justify-content: center;
            align-items: center;
            padding-bottom:1rem;
            //border-radius:9999px;
            img{
              width:6rem;
              cursor:pointer;
            }
          }
          
          input{
            display:none;
          }
          >input:checked+div {
            //border: dashed 1px $light-grey;
            border-bottom:solid .5rem $white;
          }
        }
      }
    }
  }


  .button-like {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    margin-bottom: 10vh;
    margin-top: 3rem;

    svg {
      margin-right: 8px;
      font-size: 1.2rem;
      height: 1.2rem;
      width: auto;
      position: relative;
      top: -0.1rem;
    }
  }
}