.game-intro {
  img {
    width: 100%;
  }

  .dialog-box {

    margin: 2rem 0 4rem;

    >* {
      &:nth-child(1) {
        &:not(ul) {
          &::before {
            content: "«";
            margin-right: .5rem;
          }
        }

        >li {
          &:nth-child(1) {
            &::before {
              content: "« •";
              margin-right: .5rem;
            }
          }
        }
      }

      &:last-child {
        &:not(ul) {
          &::after {
            content: "»";
            margin-left: .5rem;
          }
        }

        >li {
          &:last-child {
            &::after {
              content: "»";
              margin-left: .5rem;
            }
          }
        }
      }
    }

    p {
      margin: 1rem 0;
      text-align: center;
    }

    ul {
      text-align: center;

      li {
        margin: .5rem 0;

        &::before {
          content: "•";
          margin-right: 1rem;
        }
      }
    }
  }

  .signatures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    position: relative;
    top: -2rem;
    margin-bottom: 2rem;

    img {
      width: 35%;
      display: block;

      &:nth-child(2) {
        width: 45%;
      }
    }
  }

  .intro-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      &:not(.play-button) {
        @include round-button;
        width: 5rem;
        height: 5rem;
        margin: 0 2rem;

        svg {
          position: relative;
          top: -1px;
          left: -1px;
        }

        &.previous {
          svg {
            transform: rotate(180deg);
          }
        }

        &:hover {
          svg {
            path {
              fill: $suite-red;
            }
          }
        }
      }

      &.play-button {
        @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
      }

      &.disabled {
        opacity: .3;
        cursor: default;
        pointer-events: none;

        &:hover {
          background-color: $suite-red;
          color: white;
        }
      }
    }
  }
}