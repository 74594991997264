.home-component {
  justify-content: center;
  h1 {
    width: 60vw;
    height: 60vw;
    @include flex-column-wrap;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    border: solid 2rem $suite-red;
    border-radius: 9999px;
    padding-top: 2rem;
    font-family: Hind;
    text-transform: none;

    span {
      position: relative;
      top: -2rem;
    }
  }

  p {
    a {
      color: $suite-red;

      &:hover {
        color: $black;
      }
    }
  }

  .button-like {
    @include button($black, $suite-red, $suite-red, $suite-red, $suite-red, white, $suite-red, white);
    border-radius: .2rem;
    margin-top:4rem;

    svg {
      margin-left: 5px;
      font-size: 1.6rem;
    }

    &.unavailable{
      cursor:default !important;
      @include button($black, $dark-grey, $dark-grey, $dark-grey, $black, $dark-grey, $dark-grey, $dark-grey);

      svg{
        margin-left:0;
        margin-right:2rem;
        height:2rem;
        width:max-content;
        display:inline;
      }
      
    }
  }
}