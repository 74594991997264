header {
  width: 100%;
  flex: 0 1 auto;
  margin-bottom: 2rem;

  .season-ep{
    display:flex;
    justify-content: space-between;
    width:100%;
    li{
      font-family:Petita;
      text-transform: uppercase;
      font-size:1.4rem;
    }
  }

  img {
    width: 60%;
    display: block;
    margin: 0 auto;

    &.minified {
      width: 25%;
      &.top{
        position:relative;
        top:-1.6rem;
      }
    }

    &.lesson-header {
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  h1 {
    text-align: center !important;
    color: $white !important;
    font-size: 1.6rem !important;

    span {
      display: block;
      &.inline{
        display:inline;
        margin-left:.5rem;
        font-weight: bold !important;
      }
      font-size: inherit !important;
      color: $suite-red;
    }
  }

  &.slides-header{
    img:not(.lesson-header){
      width:15% !important;
      transition:width .3s;
    }
  }
}