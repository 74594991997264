.color-container {
  .w-color-swatch {
    width: 100%;
    max-width: 240px;

    >div {
      margin: 6px !important;
    }
  }

  button {
    @include round-button;
  }
}