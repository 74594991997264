.medal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;

  &.progress-nul {
    opacity: .5;

    img {
      filter: grayscale(1);
    }
  }

  .main {
    margin-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      font-size: 1.6rem;
    }

    img {
      height: 10rem;
      margin: .5rem 0 0;
    }
  }

  .content {
    margin-top: 1rem;
    text-align: center;

    .jauge-container {
      display: block;
      width: 80%;
      background-color: rgba(0, 0, 0, .6);
      height: 1rem;
      margin: 2rem auto 1rem;
      border-radius: 9999px;
      position: relative;
      overflow: hidden;

      .jauge-content {
        transition: width 1s, background-color 1s;
        width: 0;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $suite-red;

      }
    }

    p:nth-child(3) {
      opacity: .5;
    }
  }
}