.game-component {
  justify-content: flex-start;

  h1 {
    color: white;
    font-weight: bold;

    span {
      color: $suite-red;
      display: block;
      font-weight: normal;
      font-size: inherit;
    }
  }

  #loader {
    background-color: $black;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 2;
    flex-direction: column;
    transition: opacity .5s;

    div {
      position: absolute;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      z-index: 2;
      transition: top .5s, opacity .5s;
      top: -10vh;
      opacity: 0;

      &#slide-1 {
        top: 0;

        img {
          width: 65%;
        }

      }

      &#slide-2 {
        h2 {
          font-size: 2.2rem;
          color: $white;
          text-transform: uppercase;
          text-align: center;
          width: 90%;
          margin: 0 auto;

          span {
            display: block;
            color: $suite-red;
            font-size: inherit;
            font-weight: inherit;
            line-height: 2rem;
          }
        }

        img {
          width: 85%;
          margin: 4rem auto 0;
        }
      }
    }

    .bg-pattern {
      position: absolute;
      opacity: .1;
      width: 150vw;
      left: -25vw;
      bottom: -20vh;
      z-index: 1
    }

    &.state-1 {
      #slide-1 {
        top: 0;
        opacity: 1;
      }
    }

    &.state-2 {
      #slide-1 {
        top: 10vh;
        opacity: 0;
      }
    }

    &.state-3 {
      #slide-1 {
        top: 10vh;
        opacity: 0;
      }

      #slide-2 {
        top: 0;
        opacity: 1;
      }
    }

    &.state-4 {
      opacity: 0;
      pointer-events: none;

      #slide-1 {
        top: 10vh;
        opacity: 0;
      }

      #slide-2 {
        top: 0;
        opacity: 1;
      }
    }
  }
}