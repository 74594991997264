.profile-component {
  justify-content: flex-start;
  padding-bottom:8rem;

  .edit-profile {
    text-decoration: none;
    position: relative;
    height: 12rem;
    width: 12rem;
    display: block;

    .profile-picture {
      width: 12rem;
      height: 12rem;
      border-radius: 9999px;
      display: flex;
      margin: 0 auto 0;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;

      p {
        font-size: 5rem;
        font-weight: bold;
        color: $black;
        font-family: Arial;
      }
    }

    div:not(.profile-picture) {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      background-color: $dark-grey;
      height: 3rem;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      border: solid .2rem $black;

      svg {
        height: 60%;
        width: auto;
      }
    }

    &:hover {
      .profile-picture {
        background-color: $dark-grey !important;
      }

      div:not(.profile-picture) {
        background-color: $very-dark-grey;
      }
    }
  }

  section:nth-child(2) {
    width: 100%;

    ul {
      @include flex-wrap;
      width: 90%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      margin: 0 auto;

      li {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem;
        border-radius: 1rem;

        a {
          background-color: rgba(25,25,25,.5);
          border: solid .1rem $dark-grey;
          border-radius: 9999px;
          color: white;
          width: 100%;
          height: 100%;
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          transition: opacity .2s;
          padding: 1.5rem 2rem;

          img {
            height: 5rem;
          }

          &:hover {
            background-color: $very-dark-grey;
            border-color: $very-dark-grey;
          }
        }


      }
    }
  }

}