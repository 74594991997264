.file-component {

  padding-left: 2rem;
  padding-right: 2rem;

  .emoji {
    @include emojis;
    margin: 0 auto;
  }

  section {
    width:100%;
    @include lessons;
    .horizontal {
      display: flex;
      flex-direction: row;

      &.columns-2 {
        justify-content: space-between;
        >* {
          width: calc(50% - 1rem);
          margin: 0 .5rem;
        }

        >div {
          margin: 1rem 0;

          p {
            margin: .5rem 0;
          }
        }
      }

      &.columns-3 {
        justify-content: space-between;
        >* {
          width: calc(33% - 1rem);
          margin: 0 .5rem;
        }
      }

      &.columns-4 {
        justify-content: space-between;
        >* {
          width: calc(25% - 1rem);
          margin: 0 .5rem;
        }

        img {
          width: 100%;

          &.color-point {
            width: 4rem;
          }
        }
      }
    }
  }


  a {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    width: max-content;
    margin: 2rem auto;

    svg {
      margin-right: 8px;
      font-size: 1.2rem;
      height: 1.2rem;
      width: auto;
      position: relative;
      top: -0.1rem;
    }
  }

  .emargin {
    width: 4rem;
    display: block;
    position: fixed;
    right: 1.5rem;
    top: 1.5rem;
  }
}