.game-recap {
  text-align: center;

  .emoji {
    @include emojis;
    height:15rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  article:nth-of-type(1){
    h4{
      font-size: 2.2rem;
      .colored {
        color: $suite-red;
        font-size:inherit;
      }
      margin-bottom:3rem;
    }
    ul{
      opacity:.9;
      padding-bottom: 3rem !important;
      border-bottom:dashed 1px $dark-grey;
    }
  }

  article:nth-of-type(2){
    *{
      display:inline;
    }
    padding-bottom: 3rem !important;
    border-bottom:dashed 1px $dark-grey;
  }

  article {
    margin: 2rem 0;
    ul{
      width:95%;
      margin:0 auto;
      li{
        display:flex;
        justify-content: space-between;
      }
    }
  }

  button {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    margin: 0 auto;
  }
  
}