.achievements-component {
  justify-content: flex-start;
  position: relative;

  ul {
    width: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 3rem;
  }

  .button-like {
    @include button($suite-red, white, $suite-red, white, transparent, $suite-red, $suite-red, $suite-red);
    margin-bottom: 10vh;
    margin-top: 3rem;

    svg {
      margin-right: 8px;
      font-size: 1.2rem;
      height: 1.2rem;
      width: auto;
      position: relative;
      top: -0.1rem;
    }
  }
}